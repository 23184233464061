import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import WechatView from "../views/AboutView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/wechat",
    name: "wechat",
    component: WechatView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const allowedDomain = "NDMuMTU2LjIwNS40MA==";
//   //bG9jYWxob3N0
//   //c3RhdGljLW1wLTM4NWQ1ZjY5LWNkNTAtNGZlYy05MjNkLWUzNWRjYWVkNTgxOC5uZXh0LmJzcGFwcC5jb20=
//   const currentDomain = window.location.hostname;
//   if (btoa(currentDomain) !== allowedDomain) {
//     next(false);
//   } else {
//     next();
//   }
// });

export default router;
