<template>
  <!--  <el-watermark content="乐享开发演示，确认收货无此水印">-->
  <div class="common-layout">
    <img class="flex-img" src="../assets/eee.jpg" @click="toPage" />
    <el-container>
      <el-header>
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          :ellipsis="false"
          active-text-color="#7e3f47"
        >
          <div class="logo">
            <img
              style="width: 50px; height: 50px; border-radius: 6px"
              src="../assets/logo.jpg"
              alt="logo"
            />
            <div class="lx-title">
              <div class="lx-title-cn">DY奢品维护</div>
              <div class="lx-title-en">- DY Luxury product maintenance -</div>
            </div>
          </div>
          <div class="flex-grow" />
        </el-menu>
      </el-header>
      <el-main>
        <div class="lx-banner" @click="toPage">
          <el-image
            class="lx-banner-img"
            :src="require('../assets/banner.jpg')"
            fit="cover"
          />
        </div>
      </el-main>
    </el-container>
    <div class="lx-box1">
      <h1 v-slide-in>品牌介绍</h1>
      <div v-slide-in class="lx-box1-en">Brand</div>
      <div v-slide-in style="height: 10px"></div>
      <p v-slide-in>
        地狱是一家专注于奢侈品包包维修的高端服务品牌。我们致力于为每位顾客提供最优质的维修服务，让您的心爱之物焕然一新。
      </p>
      <p v-slide-in>
        我们的团队由一群经验丰富、技术精湛的专业人员组成，他们对各种奢侈品包包的材质和制作工艺有着深入的研究。无论是皮革修复、五金件更换，还是色彩还原等各种维修需求，我们都能够为您提供最专业的解决方案。
      </p>
      <p v-slide-in>
        在地狱，我们注重每一个细节，确保每一项维修工作都达到最高的质量标准。我们使用最先进的设备和工具，采用正品皮料和五金，力求为客户提供最好的维修效果。同时，我们还提供贴心的售后服务，让您无后顾之忧。
      </p>
      <p v-slide-in>
        我们的服务范围涵盖了各种知名品牌的奢侈品包包(香奈儿、爱马仕等一系列品牌)无论您的包包是需要修复还是保养，我们都能够为您提供一站式的服务，让您的包包恢复如新，延续其使用寿命。
      </p>
      <p v-slide-in>
        选择地狱，就是选择了专业、优质和放心。任何事做到极致就是艺术！
      </p>
    </div>

    <div class="lx-box2">
      <img v-slide-in class="lx-box2-img" src="../assets/ds-spec-img1.png" />
      <h1 v-slide-in>服务项目</h1>
      <div v-slide-in class="lx-text-en">Service items</div>
      <div class="lx-list">
        <div v-slide-in class="lx-list-item" @click="toPage">
          <div class="lx-item-img">
            <img src="../assets/FSH-1698337033834-hero1.jpg" />
          </div>
          <div class="lx-item-info">
            <div class="lx-info-title">香奈儿维修</div>
            <div class="lx-info-title-en">Chanel Repair</div>
          </div>
        </div>
        <div v-slide-in class="lx-list-item" @click="toPage">
          <div class="lx-item-img">
            <img src="../assets/portal-brand-active-1-2.png" />
          </div>
          <div class="lx-item-info">
            <div class="lx-info-title">二手奢侈品买卖</div>
            <div class="lx-info-title-en">Sale of second-hand luxury goods</div>
          </div>
        </div>
      </div>
    </div>

    <div class="lx-box3">
      <h1 v-slide-in>维修案例</h1>
      <div v-slide-in class="lx-text-en">Repair cases</div>

      <div v-slide-in class="lx-list1">
        <div class="lx-list1-item1" @click="toPage">
          <img class="lx-item1-img" src="../assets/aj4.jpg" />
        </div>
        <div class="lx-list1-item1" @click="toPage">
          <img class="lx-item1-img" src="../assets/aj5.jpg" />
        </div>
        <div class="lx-list1-item1" @click="toPage">
          <img class="lx-item1-img" src="../assets/aj6.jpg" />
        </div>
      </div>
    </div>

    <div class="lx-box4">
      <div v-slide-in class="lx-box4-content">
        <div class="lx-box4-title">在线预约</div>
        <div class="lx-box4-title-en">Online booking</div>

        <div class="lx-box4-text">
          如果您有奢侈品需要修复或者购买精品奢侈品可以点击下方按钮预约咨询
        </div>

        <div class="lx-box4-botton" @click="toPage">立即预约</div>
      </div>
    </div>

    <div class="lx-box5">
      <h1 v-slide-in>商品展示</h1>
      <div v-slide-in class="lx-text-en">Product display</div>
      <el-carousel
        v-slide-in
        style="margin-top: 40px"
        :interval="3000"
        type="card"
        :height="height + 'px'"
        arrow="always"
      >
        <el-carousel-item v-for="item in 10" :key="item" @click="toPage">
          <el-image
            style="width: 100%; height: 100%"
            :src="require(`../assets/shop${item}.jpg`)"
            :fit="isMobile ? 'contain' : 'fill'"
          />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="lx-box6">
      <h1 v-slide-in>购买流程</h1>
      <div v-slide-in class="lx-text-en">Purchase process</div>

      <div class="lx-box6-content">
        <div class="lx-box4-botton" @click="toPage">联系微信</div>
      </div>
    </div>

    <!--    <div class="lx-box6">-->
    <!--      <h1 v-slide-in>发货实拍</h1>-->
    <!--      <div v-slide-in class="lx-text-en">Actual shipment photos</div>-->
    <!--    </div>-->

    <div class="lx-box8">
      <img v-slide-in class="lx-box6-img" src="../assets/ds-spec-img2.png" />
      <h1 v-slide-in>服务宗旨</h1>
      <div v-slide-in class="lx-text-en">Service tenet</div>

      <div class="lx-slide-content">
        <div v-slide-in class="lx-content">
          <p>
            1.以客户需求为导向：认真倾听客户的声音，深入了解客户的需求，提供个性化的解决方案，力求超越客户的期望。
          </p>
          <p>
            2.坚持品质至上：无论是产品还是服务，我们都严格把控质量，确保客户始终得到最佳体验。
          </p>
          <p>
            3.诚信经营，共赢发展：坚守诚信原则，秉持诚实守信的商业道德，与客户建立长期互信的合作关系，共同成长。
          </p>
          <p>
            4.尊重与关爱：对待每一位客户都充满尊重和关爱，提供温暖、贴心的服务，让客户感受到我们的关怀。
          </p>
          <p>
            我们将不懈努力，不断提升服务水平，为客户提供卓越的服务体验，致力于成为客户值得信赖的首选品牌。
          </p>
        </div>
      </div>
    </div>

    <div class="lx-box9">
      <h1 v-slide-in>保养小贴士</h1>
      <div v-slide-in class="lx-text-en">Maintenance tips</div>

      <div v-slide-in class="lx-box9-list">
        <div class="lx-list9-item">
          <div class="lx-item9-backInfo">HARDWARE</div>
          <span class="lx-item9-title">五金氧化</span>
          <p class="lx-item9-text">
            使用氧化膏可以去除包包五金上的氧化痕迹，但如果五金磨损已经掉颜色了，则需要去镀金。
          </p>
        </div>
        <div class="lx-list9-item">
          <div class="lx-item9-backInfo">WATERPROOF</div>
          <span class="lx-item9-title">防水</span>
          <p class="lx-item9-text">
            所有的包都不能沾水，因为水渍是无法修复的。
          </p>
        </div>
        <div class="lx-list9-item">
          <div class="lx-item9-backInfo">SUNSCREEN</div>
          <span class="lx-item9-title">防晒</span>
          <p class="lx-item9-text">
            包包不能晒太阳，尤其是夏天放在车里或阳台上，晒完后皮面可能会爆膜、裂开，油边也可能会融化。
          </p>
        </div>
      </div>
    </div>

    <div class="lx-footer">
      <div class="lx-left">
        <div class="lx-footer-logo">
          <img src="../assets/logo.jpg" />
          <div class="lx-footer-title">DY奢品维护</div>
          <div class="lx-footer-title-en">
            - DY Luxury product maintenance -
          </div>
        </div>
        <div class="lx-footer-info">
          <div class="lx-footer-info-text">
            客服<span>Wechat</span>: yeezy-5D
          </div>
          <!--          <div class="lx-footer-info-text">-->
          <!--            客服2<span>Wechat</span>: DIYUTQ-->
          <!--          </div>-->
          <!--          <div class="lx-footer-info-text">-->
          <!--            客服3<span>Wechat</span>: DYTQ-lcf-->
          <!--          </div>-->
        </div>
      </div>
      <div class="lx-qrcode">
        <img class="lx-qr" src="../assets/whatsapp.jpg" />
      </div>
    </div>
  </div>
  <!--  </el-watermark>-->
</template>

<script lang="ts" setup>
// const content = [
//   "乐享软件开发演示站",
//   "收货后去除此水印",
//   "咸鱼搜索: 程序员乐享",
// ];
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

const height = ref(
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    window.navigator.userAgent
  )
    ? 200
    : 400
);

const isMobile = ref(window.innerWidth < 768);

const handleResize = () => {
  isMobile.value = window.innerWidth < 768;
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

const route = useRouter();
const toPage = () => {
  window.open("/wechat", "_blank");
};
</script>

<style lang="scss">
.flex-grow {
  flex-grow: 1;
}
.common-layout {
  position: absolute;
}
.flex-img {
  position: fixed;
  right: 10px;
  top: 20%;
  width: 200px;
  z-index: 9999;
  border-radius: 10px;
  opacity: 0.5;
  transition: 1s;
  box-shadow: 0 0 10px #333333;
}
.flex-img:hover {
  opacity: 1;
}
.logo {
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 6px;
  user-select: none;
}

.lx-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lx-title-cn {
  font-size: 20px;
  letter-spacing: 10px;
  //color: #7e3f47;
}

.lx-title-en {
  font-size: 10px;
  color: #7e3f47;
}

.el-menu-item:hover {
  background: #7e3f47 !important;
  color: gold !important;
}

.el-menu-item:active {
  background: #7e3f47 !important;
  color: gold !important;
}

.el-menu-item:focus {
  background: #7e3f47 !important;
  color: gold !important;
}

@media (max-width: 767px) {
  .flex-img {
    width: 160px !important;
    opacity: 1 !important;
  }
  .lx-banner {
    height: 200px !important;
  }
  .lx-list {
    display: block !important;
    margin: 20px !important;
  }

  .lx-list-item {
    margin-top: 20px;
  }

  .lx-item-img img {
    height: 200px !important;
  }

  .lx-box1 {
    padding: 60px 20px !important;
  }
  .lx-box6 {
    padding: 0 20px !important;
  }
  .lx-box2-img {
    width: 100px !important;
    top: -90px !important;
  }
  .lx-box6-img {
    width: 100px !important;
    top: -90px !important;
  }
  .lx-list1 {
    display: block !important;
  }
  .lx-list1-item1 {
    margin-top: 20px;
    opacity: 1 !important;
  }
  .lx-box3 {
    margin-top: 0 !important;
  }
  .lx-footer {
    display: block !important;
    padding: 20px !important;
  }
  .lx-left {
    display: block !important;
  }
  .lx-footer-info {
    margin-top: 40px;
    color: #fff !important;
    padding-left: 0 !important;
    border: none !important;
  }

  .lx-qrcode {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
  .lx-footer-logo {
    margin-right: 0 !important;
  }

  .lx-slide-content {
    padding: 40px 20px !important;
  }
  .lx-content {
    padding-top: 20px !important;
  }
  .lx-box9-list {
    display: block !important;
    padding: 30px 0 !important;
  }
  .lx-list9-item {
    margin-top: 20px !important;
  }
}

.lx-banner {
  width: 100%;
  border-radius: 10px;
  height: calc(100vh - 100px);
  overflow: hidden;
}

.lx-banner-img {
  width: 100%;
  height: 100%;
}

.lx-box1 {
  background: #841d26;
  padding: 100px 20px;
  text-align: center;
  color: #fff;
  user-select: none;
  background-image: url("../assets/line2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.lx-box2 {
  background: #fff;
  padding: 60px 20px;
  color: #333;
  text-align: center;
  position: relative;
  user-select: none;
}

.lx-box3 {
  padding: 160px 20px 60px 20px;
  color: #333;
  text-align: center;
  position: relative;
  user-select: none;
  background-image: url("../assets/rp-index-con3-4-bg.svg");
  background-size: cover;
  margin-top: 40px;
}

.lx-box5 {
  background: #fff;
  padding: 60px 20px;
  color: #333;
  text-align: center;
  position: relative;
  user-select: none;
}

.lx-box6 {
  background: #fff;
  padding: 60px 20px;
  color: #333;
  text-align: center;
  position: relative;
  user-select: none;
}

.lx-box8 {
  background: #fff;
  padding: 60px 20px;
  color: #333;
  text-align: center;
  position: relative;
  user-select: none;
}

.lx-box9 {
  background: #fff;
  padding: 60px 20px;
  color: #333;
  text-align: center;
  position: relative;
  user-select: none;
}

.lx-box2-img {
  position: absolute;
  width: 200px;
  top: -160px;
  left: 0;
}

.lx-box6-img {
  position: absolute;
  width: 200px;
  top: -120px;
  right: 0;
}

.lx-box1 h1 {
  margin-bottom: 10px;
  position: relative;
}

.lx-box1-en {
  padding-bottom: 10px;
  border-bottom: 2px solid #fff;
  opacity: 0.8;
}

p {
  line-height: 30px;
  letter-spacing: 2px;
  font-size: 15px;
  margin-top: 10px;
}

.lx-text-en {
  letter-spacing: 2px;
  margin-top: 4px;
  color: #888;
  font-weight: bold;
}

.lx-list {
  margin: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.lx-list-item {
  flex: 1;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.lx-item-img {
  width: 100%;
  padding: 10px;
  border: 1px solid #e9eef2;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lx-item-img img {
  width: 100%;
  border-radius: 10px;
  height: 400px;
}

.lx-item-info {
  margin-top: 20px;
  text-align: center;
}

.lx-info-title {
  font-size: 24px;
  line-height: 32px;
  color: #353030;
  font-weight: bold;
}

.lx-info-title-en {
  font-size: 16px;
  line-height: 24px;
  color: rgba(53, 48, 48, 0.7);
  margin: 16px auto 0;
}

.lx-list1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
}

.lx-list1-item1 {
  flex: 1;
  flex-shrink: 0;
  //height: 600px;
  border-radius: 20px;
  overflow: hidden;
  opacity: 0.5;
  transition: 1.5s;
  border: 4px solid #fff;
  cursor: pointer;
}

.lx-list1-item1:hover {
  opacity: 1;
  transform: scale(1.05);
}

.lx-item1-img {
  width: 100%;
  height: 100%;
}

.lx-box4 {
  background: #fff;
  //padding: 60px 20px;
  padding: 120px 20px 60px 20px;
  color: #333;
  position: relative;
  user-select: none;
}

.lx-box4-content {
  width: 80%;
  margin: 0 auto;
  padding: 40px;
  border-radius: 20px;
  background: linear-gradient(104deg, #ffffff, #fcd3d3 100%);
}

.lx-box4-title {
  font-size: 32px;
  font-weight: bold;
  color: #353030;
  line-height: 40px;
}

.lx-box4-title-en {
  color: #888;
}

.lx-box4-text {
  margin: 24px 0 48px;
  color: rgba(53, 48, 48, 0.7);
  font-size: 20px;
  line-height: 28px;
}

.lx-box4-botton {
  display: block;
  width: 200px;
  line-height: 48px;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s;
  background-color: #841d26;
  border-radius: 8px;
  cursor: pointer;
}

.lx-footer {
  background: #222;
  padding: 60px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.lx-footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
}

.lx-footer-logo img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.lx-footer-title {
  font-size: 18px;
  margin-top: 20px;
  color: #fff;
  letter-spacing: 10px;
}

.lx-footer-title-en {
  color: #fff;
  font-size: 12px;
  margin-top: 10px;
}

.lx-footer-info {
  color: #d3d3d3;
  padding-left: 50px;
  font-size: 14px;
  border-left: 1px solid #fff;
}

.lx-footer-info-text:first-child {
  margin-top: 0 !important;
}

.lx-footer-info-text {
  margin-top: 30px;
}
.lx-footer-info-text span {
  color: #fff;
  padding: 0 4px;
}

.lx-left {
  display: flex;
  align-items: center;
}

.lx-qr {
  width: 150px;
  //height: 150px;
}

.lx-shop {
  width: 100%;
  height: 100%;
}

.el-carousel__indicators {
  display: none !important;
}
.lx-slide-content {
  padding: 40px 120px;
  max-width: 1210px;
}
.lx-content {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  color: #353030;
}
.lx-content p {
  z-index: 99;
  position: relative;
}
.lx-content::before {
  background: url("../assets/idoc-ui14.png");
  width: 40px;
  height: 32px;
  background-size: 100% auto;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  z-index: 2;
}
.lx-content::after {
  background: url("../assets/idoc-ui15.png");
  width: 120px;
  height: 96px;
  background-size: 100% auto;
  position: absolute;
  right: 0;
  bottom: -48px;
  content: "";
}
.lx-box9-list {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 80px 0 40px 0;
}

.lx-list9-item {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lx-item9-backInfo {
  font-size: 40px;
  font-weight: bold;
  background: linear-gradient(
    to bottom,
    rgb(239, 239, 239) 30%,
    rgba(255, 255, 255, 0) 100%
  );
  -webkit-background-clip: text;
  color: transparent;
}

.lx-item9-title {
  display: block;
  color: #000000;
  font-size: 32px;
  line-height: 40px;
  margin-top: -30px;
}

.lx-box6-content {
  margin-top: 30px;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
