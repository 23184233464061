<template>
  <div class="subpage">
    <el-carousel
      class="video-box"
      :autoplay="false"
      @change="handleSlideChange"
      arrow="always"
    >
      <el-carousel-item v-for="(video, index) in videos" :key="index">
        <video :id="'video' + index" :src="video.src" controls></video>
      </el-carousel-item>
    </el-carousel>
    <div class="contact-us">
      <p>联系我们请加WeChat</p>
      <div class="lx-wechat" @click="copyText('Yyg-sss')">
        <svg
          t="1705308724256"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="6728"
          width="30"
          height="30"
        >
          <path
            d="M146.286 0h731.428A146.286 146.286 0 0 1 1024 146.286v731.428A146.286 146.286 0 0 1 877.714 1024H146.286A146.286 146.286 0 0 1 0 877.714V146.286A146.286 146.286 0 0 1 146.286 0z"
            fill="#1AAD19"
            p-id="6729"
          ></path>
          <path
            d="M411.063 219.429c-145.554 0-264.777 95.524-264.777 216.758 0 69.998 39.68 127.452 105.947 171.996l-26.478 76.69 92.563-44.69c33.133 6.327 59.684 12.8 92.745 12.8a325.486 325.486 0 0 0 24.722-1.024 183.845 183.845 0 0 1-8.155-53.394c0-111.287 99.364-201.655 225.133-201.655 8.558 0 17.08 0.621 25.49 1.5-22.93-102.693-136.96-178.981-267.19-178.981z m-86.016 172.105c-19.822 0-39.863-12.8-39.863-31.89 0-19.2 20.041-31.818 39.863-31.818s33.06 12.617 33.06 31.817c0 19.09-13.202 31.89-33.06 31.89z m185.344 0c-19.858 0-39.753-12.8-39.753-31.89 0-19.2 19.931-31.818 39.753-31.818 19.931 0 33.134 12.617 33.134 31.817 0 19.09-13.203 31.89-33.134 31.89z"
            fill="#FFFFFF"
            p-id="6730"
          ></path>
          <path
            d="M877.714 605.513c0-102.766-101.705-186.587-215.954-186.587-120.978 0-216.247 83.821-216.247 186.587 0 102.985 95.269 186.66 216.247 186.66 25.307 0 50.834-6.436 76.288-12.909l69.742 38.62-19.127-64.293c51.017-38.73 89.051-90.076 89.051-148.078z m-286.061-32.146c-12.654 0-25.454-12.727-25.454-25.71 0-12.836 12.763-25.746 25.454-25.746 19.236 0 31.817 12.91 31.817 25.746 0 12.983-12.58 25.71-31.817 25.71z m139.849 0c-12.58 0-25.271-12.727-25.271-25.71 0-12.836 12.69-25.746 25.27-25.746 19.054 0 31.818 12.91 31.818 25.746 0 12.983-12.764 25.71-31.817 25.71z"
            fill="#FFFFFF"
            p-id="6731"
          ></path></svg
        >客服1: Yyg-sss
      </div>
      <div class="lx-wechat" @click="copyText('DIYUTQ')">
        <svg
          t="1705308724256"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="6728"
          width="30"
          height="30"
        >
          <path
            d="M146.286 0h731.428A146.286 146.286 0 0 1 1024 146.286v731.428A146.286 146.286 0 0 1 877.714 1024H146.286A146.286 146.286 0 0 1 0 877.714V146.286A146.286 146.286 0 0 1 146.286 0z"
            fill="#1AAD19"
            p-id="6729"
          ></path>
          <path
            d="M411.063 219.429c-145.554 0-264.777 95.524-264.777 216.758 0 69.998 39.68 127.452 105.947 171.996l-26.478 76.69 92.563-44.69c33.133 6.327 59.684 12.8 92.745 12.8a325.486 325.486 0 0 0 24.722-1.024 183.845 183.845 0 0 1-8.155-53.394c0-111.287 99.364-201.655 225.133-201.655 8.558 0 17.08 0.621 25.49 1.5-22.93-102.693-136.96-178.981-267.19-178.981z m-86.016 172.105c-19.822 0-39.863-12.8-39.863-31.89 0-19.2 20.041-31.818 39.863-31.818s33.06 12.617 33.06 31.817c0 19.09-13.202 31.89-33.06 31.89z m185.344 0c-19.858 0-39.753-12.8-39.753-31.89 0-19.2 19.931-31.818 39.753-31.818 19.931 0 33.134 12.617 33.134 31.817 0 19.09-13.203 31.89-33.134 31.89z"
            fill="#FFFFFF"
            p-id="6730"
          ></path>
          <path
            d="M877.714 605.513c0-102.766-101.705-186.587-215.954-186.587-120.978 0-216.247 83.821-216.247 186.587 0 102.985 95.269 186.66 216.247 186.66 25.307 0 50.834-6.436 76.288-12.909l69.742 38.62-19.127-64.293c51.017-38.73 89.051-90.076 89.051-148.078z m-286.061-32.146c-12.654 0-25.454-12.727-25.454-25.71 0-12.836 12.763-25.746 25.454-25.746 19.236 0 31.817 12.91 31.817 25.746 0 12.983-12.58 25.71-31.817 25.71z m139.849 0c-12.58 0-25.271-12.727-25.271-25.71 0-12.836 12.69-25.746 25.27-25.746 19.054 0 31.818 12.91 31.818 25.746 0 12.983-12.764 25.71-31.817 25.71z"
            fill="#FFFFFF"
            p-id="6731"
          ></path></svg
        >客服2: DIYUTQ
      </div>
      <div class="lx-wechat" @click="copyText('DYTQ-lcf')">
        <svg
          t="1705308724256"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="6728"
          width="30"
          height="30"
        >
          <path
            d="M146.286 0h731.428A146.286 146.286 0 0 1 1024 146.286v731.428A146.286 146.286 0 0 1 877.714 1024H146.286A146.286 146.286 0 0 1 0 877.714V146.286A146.286 146.286 0 0 1 146.286 0z"
            fill="#1AAD19"
            p-id="6729"
          ></path>
          <path
            d="M411.063 219.429c-145.554 0-264.777 95.524-264.777 216.758 0 69.998 39.68 127.452 105.947 171.996l-26.478 76.69 92.563-44.69c33.133 6.327 59.684 12.8 92.745 12.8a325.486 325.486 0 0 0 24.722-1.024 183.845 183.845 0 0 1-8.155-53.394c0-111.287 99.364-201.655 225.133-201.655 8.558 0 17.08 0.621 25.49 1.5-22.93-102.693-136.96-178.981-267.19-178.981z m-86.016 172.105c-19.822 0-39.863-12.8-39.863-31.89 0-19.2 20.041-31.818 39.863-31.818s33.06 12.617 33.06 31.817c0 19.09-13.202 31.89-33.06 31.89z m185.344 0c-19.858 0-39.753-12.8-39.753-31.89 0-19.2 19.931-31.818 39.753-31.818 19.931 0 33.134 12.617 33.134 31.817 0 19.09-13.203 31.89-33.134 31.89z"
            fill="#FFFFFF"
            p-id="6730"
          ></path>
          <path
            d="M877.714 605.513c0-102.766-101.705-186.587-215.954-186.587-120.978 0-216.247 83.821-216.247 186.587 0 102.985 95.269 186.66 216.247 186.66 25.307 0 50.834-6.436 76.288-12.909l69.742 38.62-19.127-64.293c51.017-38.73 89.051-90.076 89.051-148.078z m-286.061-32.146c-12.654 0-25.454-12.727-25.454-25.71 0-12.836 12.763-25.746 25.454-25.746 19.236 0 31.817 12.91 31.817 25.746 0 12.983-12.58 25.71-31.817 25.71z m139.849 0c-12.58 0-25.271-12.727-25.271-25.71 0-12.836 12.69-25.746 25.27-25.746 19.054 0 31.818 12.91 31.818 25.746 0 12.983-12.764 25.71-31.817 25.71z"
            fill="#FFFFFF"
            p-id="6731"
          ></path></svg
        >客服3: DYTQ-lcf
      </div>
    </div>
    <img class="lx-wechat-img" src="../assets/wechat.jpg" />
  </div>
</template>

<script setup>
import { ElMessage } from "element-plus";
import { ref } from "vue";
const videos = [
  { src: "../2023_03_13_21_37_IMG_7867.MOV" },
  { src: "../1月8日.MP4" },
  { src: "../2023_10_09_13_41_IMG_4664.MOV" },
];

const copyText = (text) => {
  const el = document.createElement("textarea");
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  ElMessage({
    message: "复制成功",
    type: "success",
  });
};

const activeIndex = ref(0);

const handleSlideChange = (index) => {
  const prevIndex = activeIndex.value;
  activeIndex.value = index;
  const prevVideo = document.getElementById("video" + prevIndex);
  const currentVideo = document.getElementById("video" + index);
  if (prevVideo) {
    prevVideo.pause(); // 暂停前一个视频
  }
  if (currentVideo) {
    currentVideo.play(); // 播放当前视频
  }
};
</script>

<style lang="scss">
.subpage {
  padding: 20px;
}

.video-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.video-item {
  flex: 0 0 auto;
  scroll-snap-align: start;
}

video {
  width: 100%;
}

.contact-us {
  margin-top: 20px;
  text-align: center;
}

.lx-wechat {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  background: #42b98330;
  border-radius: 5px;
  padding: 10px 0;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }
}

video {
  height: 100%;
}

.lx-wechat-img {
  width: 50%;
  margin-top: 20px;
  position: relative;
  left: 25%;
}

@media (max-width: 767px) {
  .lx-wechat-img {
    width: 100%;
    position: static !important;
  }
}
</style>
