const DISTANCE = 150;
const DURATION = 500;
const map = new WeakMap();
const ob = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
  for (const entry of entries) {
    if (entry.isIntersecting) {
      const animation = map.get(entry.target);
      if (animation) {
        animation.play();
        ob.unobserve(entry.target);
      }
    }
  }
});

function isBelowViewPort(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return rect.top - DISTANCE > window.innerHeight;
}

export const customIntersectionDirective = {
  mounted(el: HTMLElement) {
    if (!isBelowViewPort(el)) {
      return;
    }
    const animation = el.animate(
      [
        {
          transform: `translateY(${DISTANCE}px)`,
          opacity: 0.5,
        },
        {
          transform: `translateY(0)`,
          opacity: 1,
        },
      ],
      {
        duration: DURATION,
        easing: "ease-in-out",
        fill: "forwards",
      }
    );
    animation.pause();
    ob.observe(el);
    map.set(el, animation);
  },
  unmounted(el: HTMLElement) {
    ob.unobserve(el);
  },
};
